import DOMPurify from 'isomorphic-dompurify'
import React, { useEffect, useMemo, useState } from 'react'

type Props = {
    lang: any
    langCode: string
    isMobile: boolean
}


const article = {
    en: `
        <div>
            <div style="font-size:16px;text-align:justify;margin-bottom:20px">
                <h2 style="font-size: 24px; font-weight: 700; color: #000000">Looking for Your Dream Apartment in Jakarta, Tangerang, Bekasi, or Depok? Jendela360 Is the Solution!</h2>
                <br >
                <p>Finding the right apartment to rent in Jakarta, Tangerang, Bekasi, or Depok can be a challenge. There are many factors to consider, from strategic location, budget-friendly rental prices, and complete facilities to a safe environment.</p>
                <br >
                <p>At Jendela360, we understand your needs. We are a trusted apartment rental website offering the most comprehensive selection across the Jakarta, Tangerang, Bekasi, and Depok areas.</p>
            </div>
            <div style="font-size:16px;text-align:justify;margin-bottom:20px">
                <h2 style="font-size: 24px; font-weight: 700; color: #000000">Why Choose Jendela360 for Renting an Apartment?</h2>
                <br >
                <div style="padding-left:20px">
                    <ul style="padding-left:15px">
                        <li><b>Extensive Choices:</b> Jendela360 offers over 32,000 ready-to-move-in apartments spread across Jakarta, Tangerang, Bekasi, and Depok. You can easily find an apartment that meets your criteria, from cozy studios to spacious family units.</li>
                        <li><b>Best Prices:</b> We work directly with apartment owners, so you get the best rental prices without intermediary fees. You can also take advantage of installment options up to 12 months using a credit card or other financial solutions to ease your monthly rental payments.</li>
                        <li><b>100% Verified Listings:</b> All apartment listings on Jendela360 are verified by our team. This ensures you get accurate information and avoid fraud.</li>
                        <li><b>Dedicated Sales and Aftersales:</b> Say goodbye to dealing with multiple people when renting an apartment. You'll communicate with one dedicated sales and aftersales representative throughout your rental period with Jendela360.</li>
                        <li><b>Easy & Fast Search:</b> Use our intuitive search filters to narrow down options based on location, price, unit type, facilities, and other criteria. Find your dream apartment in just seconds!</li>
                        <li><b>Friendly Service:</b> Our friendly and professional customer service team is ready to assist you in your apartment search. We will answer your questions and provide recommendations based on your needs.</li>
                    </ul>
                </div>
            </div>
            <div style="font-size:16px;text-align:justify;margin-bottom:20px">
                <h2 style="font-size: 24px; font-weight: 700; color: #000000">Start Your Apartment Search Now!</h2>
                <br >
                <p>With Jendela360, finding your dream apartment in <a style="text-decoration:underline;color:blue" href="https://jendela360.com/apartemen/jakarta">Jakarta</a>, <a style="text-decoration:underline;color:blue" href="https://jendela360.com/apartemen/tangerang">Tangerang</a>, <a style="text-decoration:underline;color:blue" href="https://jendela360.com/apartemen/bekasi">Bekasi</a>, or <a style="text-decoration:underline;color:blue" href="https://jendela360.com/apartemen/depok">Depok</a> is easy and enjoyable. Follow these simple steps to get started:</p>
                <br >
                <div style="padding-left:20px">
                    <ol style="padding-left:15px">
                        <li>Visit the Jendela360 website at <a style="text-decoration:underline;color:blue" href="https://jendela360.com">https://jendela360.com/</a>.</li>
                        <li>Use the search filters to choose your preferred location, price, unit type, and facilities.</li>
                        <li>View the details of the apartment listings that catch your eye.</li>
                        <li>Contact a dedicated sales advisor through our website or via our customer service team.</li>
                    </ol>
                </div>
                <br >
                <p>Jendela360 is the trusted platform for finding the best apartments that match your lifestyle and budget. Don't hesitate to contact us if you have any questions.</p>
                <br >
                <b>Find your dream apartment in Jakarta, Tangerang, Bekasi, or Depok with Jendela360!</b>
            </div>
        </div>
    `,
    id: `
        <div>
            <div style="font-size:16px;text-align:justify;margin-bottom:20px">
                <h2 style="font-size: 24px; font-weight: 700; color: #000000">Mencari Apartemen Impian di Jakarta, Tangerang, Bekasi, Depok? Jendela360 Solusinya!</h2>
                <br >
                <p>Menemukan apartemen yang tepat untuk disewa di Jakarta, Tangerang, Bekasi, atau Depok bisa menjadi tantangan tersendiri. Ada banyak faktor yang perlu dipertimbangkan, mulai dari lokasi strategis, harga sewa yang sesuai budget, fasilitas lengkap, hingga keamanan lingkungan.</p>
                <br >
                <p>Di Jendela360, kami mengerti kebutuhan Anda. Kami adalah website sewa apartemen terpercaya yang menawarkan pilihan terlengkap dengan jangkauan area Jakarta, Tangerang, Bekasi, dan Depok.</p>
            </div>
            <div style="font-size:16px;text-align:justify;margin-bottom:20px">
                <h2 style="font-size: 24px; font-weight: 700; color: #000000">Mengapa Memilih Jendela360 untuk Sewa Apartemen?</h2>
                <br >
                <div style="padding-left:20px">
                    <ul style="padding-left:15px">
                        <li><b>Pilihan Terlengkap:</b> Jendela360 menyediakan lebih dari 32,000 unit apartemen siap huni yang tersebar di berbagai wilayah Jakarta, Tangerang, Bekasi, dan Depok. Anda dapat dengan mudah menemukan apartemen yang sesuai dengan kriteria Anda, mulai dari studio yang nyaman hingga unit keluarga yang luas.</li>
                        <li><b>Harga Terbaik:</b> Kami bekerjasama langsung dengan pemilik apartemen sehingga Anda bisa mendapatkan harga sewa terbaik tanpa biaya perantara. Anda juga bisa memanfaatkan fitur cicilan hingga 12x dengan menggunakan kartu kredit atau solusi finansial lainnya untuk meringankan biaya sewa bulanan.</li>
                        <li><b>100% Listing Terverifikasi:</b> Semua listing apartemen di Jendela360 telah diverifikasi oleh tim kami. Ini memastikan Anda mendapatkan informasi akurat dan menghindari penipuan.</li>
                        <li><b>Dedicated Sales dan Aftersales:</b> Bye bye dengan ganti ganti orang saat mengurus sewa apartemen. Karena Anda hanya akan berkomunikasi dengan satu dedicated sales dan aftersales selama Anda menyewa di Jendela360</li>
                        <li><b>Pencarian Mudah & Cepat:</b> Gunakan filter pencarian intuitif kami untuk mempersempit pilihan berdasarkan lokasi, harga, tipe unit, fasilitas, dan kriteria lainnya. Temukan apartemen impian Anda hanya dalam hitungan detik!</li>
                        <li><b>Layanan Ramah:</b> Tim customer service kami yang ramah dan profesional siap membantu Anda dalam proses pencarian apartemen. Kami akan menjawab pertanyaan Anda dan memberikan rekomendasi sesuai dengan kebutuhan Anda.</li>
                    </ul>
                </div>
            </div>
            <div style="font-size:16px;text-align:justify;margin-bottom:20px">
                <h2 style="font-size: 24px; font-weight: 700; color: #000000">Mulai Pencarian Sewa Apartemen Anda Sekarang!</h2>
                <br >
                <p>Dengan Jendela360, mencari apartemen idaman di <a style="text-decoration:underline;color:blue" href="https://jendela360.com/apartemen/jakarta">Jakarta</a>, <a style="text-decoration:underline;color:blue" href="https://jendela360.com/apartemen/tangerang">Tangerang</a>, <a style="text-decoration:underline;color:blue" href="https://jendela360.com/apartemen/bekasi">Bekasi</a>, atau <a style="text-decoration:underline;color:blue" href="https://jendela360.com/apartemen/depok">Depok</a> menjadi mudah dan menyenangkan. Berikut langkah mudah untuk memulai pencarian Anda:</p>
                <br >
                <div style="padding-left:20px">
                    <ol style="padding-left:15px">
                        <li>Kunjungi website Jendela360 di <a style="text-decoration:underline;color:blue" href="https://jendela360.com">https://jendela360.com/</a>.</li>
                        <li>Gunakan filter pencarian untuk memilih lokasi, harga, tipe unit, dan fasilitas yang Anda inginkan.</li>
                        <li>Lihat detail listing apartemen yang menarik perhatian Anda.</li>
                        <li>Hubungi dedicated sales advisor melalui website kami atau melalui tim customer service kami.</li>
                    </ol>
                </div>
                <br >
                <p>Jendela360 adalah platform tepercaya untuk menemukan apartemen terbaik sesuai dengan gaya hidup dan budget Anda. Jangan ragu untuk menghubungi kami jika Anda memiliki pertanyaan.</p>
                <br >
                <b>Temukan apartemen impian Anda di Jakarta, Tangerang, Bekasi, Depok bersama Jendela360!</b>

            </div>
        </div> 
        
    `
}

const HomepageArticleSection = ({ lang, langCode, isMobile }: Props) => {
    const [isMore, setIsMore] = useState(true)

    const getArticle = useMemo(() => {
        const choosenArticle = langCode === 'en' ? article.en : article.id
        if (isMobile) {
            return (
                <div
                    style={isMore ? {} : {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        maxHeight: 360,
                    }}
                    className={`bg-white text-black-48 px-4 py-8 lg:px-10 lg:py-10  rounded-lg shadow-none lg:shadow-lg`}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(langCode === 'en' ? choosenArticle.replace(`:title`, lang.alt_mobile_image) : choosenArticle) }}
                >
                </div>
            )
        } else {
            return choosenArticle
                .split('<div style="font-size:16px;text-align:justify;margin-bottom:20px">')
                .map((finalParagraph, index) => ((finalParagraph && index !== 0) ?
                    <div className="bg-white text-black-48 px-4 mb-10 py-8 lg:px-10 lg:py-10  rounded-lg shadow-none lg:shadow-lg" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(langCode === 'en' ? `<div style="font-size:16px;text-align:justify;margin-bottom:20px">${finalParagraph}`.replace(`:title`, lang.alt_mobile_image) : `<div style="font-size:16px;text-align:justify;margin-bottom:20px">${finalParagraph}`) }}>
                    </div> :
                    null
                ))
        }
    }, [langCode, article, isMore, isMobile])

    useEffect(() => {
        if (isMobile) {
            setIsMore(false)
        }
    }, [isMobile])

    return (
        <div className='bg-white lg:bg-gray-f7f7 w-full py-0 lg:py-12'>
            <div className='lg:container px-4 relative'>
                {getArticle}
                {
                    isMore ? null :
                        <div className='mb-8 px-4'>
                            <div className='relative bg-gradient-to-t from-white to-transparent h-5' style={{ top: -20 }}></div>
                            <button onClick={() => setIsMore(!isMore)} className='lg:hidden flex outline-none focus:outline-none text-main font-semibold capitalize'>Baca Selengkapnya</button>
                        </div>
                }
            </div>
        </div>
    )
}

export default HomepageArticleSection